/* eslint-disable max-lines */
import { CommonModule, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { EmailService } from 'src/app/services/email.service';
import { QuoteService } from 'src/app/services/quote.service';
import { StorageService } from 'src/app/services/storage.service';
import { UrlHelperService } from 'src/app/services/url-helper.service';
import { ExtraElements, quoteEdited, QuoteItem, quoteSent } from 'src/app/types/quote';
import { checkIfFormIsModified, markFormControlsAsDirty } from 'src/app/utils/forms';
import {
  showSaveSuccess,
  showSaveError,
  showSendSuccess,
  showSendError,
} from 'src/app/utils/toasts';
import { environment } from 'src/environments/environment';

interface DropdownType {
  name: string;
  value: string;
}

interface DropdownTypeNumber {
  name: string;
  value: number;
}

// export enum StairType {
//   HOLZWANGENTREPPE = 'Holzwangentreppe',
//   STAHLWANGENTREPPE = 'Stahlwangentreppe',
// }

@Component({
  selector: 'app-preise-edit',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ButtonModule,
    CalendarModule,
    ConfirmDialogModule,
    DropdownModule,
    InputSwitchModule,
    InputTextModule,
    FormsModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextareaModule,
    TabViewModule,
    NgFor,
    CommonModule,
  ],
  providers: [ConfirmationService, UrlHelperService],
  templateUrl: './preise-edit.component.html',
  styleUrl: './preise-edit.component.less',
})
export class PreiseEditComponent implements OnInit {
  public preiseForm: FormGroup;

  public quote: QuoteItem = <QuoteItem>{};
  public pdfUrl: SafeResourceUrl;

  public isAddMode: boolean = false;
  public showCeilingCasingLength: boolean;

  public salutationOptions: DropdownType[];
  public stairTypes: DropdownType[];
  public stairWoodTypes: DropdownType[];
  public stairForms: DropdownType[];
  public railingTypes: DropdownType[];
  public stairHeights: DropdownTypeNumber[] = [];
  public stairAmount: DropdownTypeNumber[] = [];

  public lastQuoteSent: quoteSent;
  public lastQuoteEdited: quoteEdited;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private quoteService: QuoteService,
    private emailService: EmailService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    public confirmationService: ConfirmationService,
    public urlHelperService: UrlHelperService,
    public sanitizer: DomSanitizer,
  ) {}

  // eslint-disable-next-line max-lines-per-function
  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      const quoteId = params['id'];
      this.isAddMode = !quoteId;
      if (!this.isAddMode) {
        this.quoteService.getSingleQuote(+quoteId).subscribe(quote => {
          this.quote = quote;
          this.buildForm(this.quote);
          this.getLastSentEdited(this.quote);
          this.getPdf(quote.pdfFileName);
        });
      } else {
        this.buildForm();
      }
    });

    this.stairTypes = [
      { name: 'Holzwangentreppe', value: 'holzwangentreppe' },
      { name: 'Stahlwangentreppe', value: 'stahlwangentreppe' },
      { name: 'Mittelholmtreppe', value: 'mittelholmtreppe' },
      { name: 'Faltwerktreppe', value: 'faltwerktreppe' },
      { name: 'Faltwerkoptiktreppe', value: 'faltwerkoptiktreppe' },
      { name: 'Kragarmtreppe', value: 'kragarmtreppe' },
      { name: 'Beton', value: 'beton' },
    ];

    this.stairWoodTypes = [
      { name: 'Kiefer', value: 'kiefer' },
      { name: 'Buche', value: 'buche' },
      { name: 'Ahorn', value: 'ahorn' },
      { name: 'Esche', value: 'esche' },
      { name: 'Eiche', value: 'eiche' },
      { name: 'Kirsche', value: 'kirsche' },
    ];

    this.stairForms = [
      { name: 'Gerade', value: 'gerade' },
      { name: '1/4 gewendelt', value: '1/4' },
      { name: '2/4 gewendelt', value: '2/4' },
      { name: '1/2 gewendelt', value: '1/2' },
      { name: '1/2 gewendelt mit Podest', value: '1/2 mit podest' },
      { name: 'Spindeltreppe', value: 'spindel' },
    ];

    // this.railingTypes = [
    //   { name: 'Ohne Geländer', value: 'ohne geländer' },
    //   { name: 'Standard Holz', value: 'standard holz' },
    //   { name: 'Edelstahl', value: 'edelstahl' },
    //   { name: 'Glas mit Holz', value: 'glas mit Holz' },
    //   { name: 'Glas mit Edelstahl', value: 'glas mit Edelstahl' },
    //   { name: 'Stahl', value: 'stahl' },
    //   { name: 'Sicherheitsglas', value: 'sicherheitsglas' },
    // ];

    this.railingTypes = [
      { name: 'Ohne Geländer', value: 'ohne gelander' },
      { name: 'Standard Holz', value: 'standard holz' },
      { name: 'Stahlstäbe', value: 'stahlstabe' },
      { name: 'Glas mit Holz', value: 'glas mit holz' },
      { name: 'Glas mit Edelstahl', value: 'glas mit edelstahl' },
      { name: 'Sicherheitsglas seitlich befestigt', value: 'sicherheitsglas seitlich befestigt' },
      { name: 'Glas mit Stahlprofil', value: 'glas mit stahlprofil' },
      { name: 'Sicherheitsglas eingelassen', value: 'sicherheitsglas eingelassen' },
      { name: 'Relinggeländer', value: 'relinggelander' },
      { name: 'Vollholzgeländer', value: 'vollholzgelander' },
    ];

    this.salutationOptions = [
      { name: 'Herr', value: 'herr' },
      { name: 'Frau', value: 'frau' },
    ];

    for (let i = 1; i <= 22; i++) {
      this.stairHeights.push({ name: `${i}`, value: i });
    }
    for (let i = 1; i <= 100; i++) {
      this.stairAmount.push({ name: `${i}`, value: i });
    }
  }

  // eslint-disable-next-line max-lines-per-function
  public buildForm(quote?: QuoteItem) {
    if (quote) {
      const extraElements: ExtraElements[] = quote.extraElements;

      this.preiseForm = this.formBuilder.group({
        id: [quote.id, [Validators.required]],
        offerNumber: [quote.offerNumber, [Validators.required]],
        salutation: [quote.salutation, [Validators.required]],
        name: [quote.name, [Validators.required]],
        surname: [quote.surname, [Validators.required]],
        email: [quote.email, [Validators.required, Validators.email]],
        postalCode: [quote.postalCode, [Validators.required]],
        phoneNumber: [quote.phoneNumber, [Validators.required]],
        installationDate: [new Date(quote.installationDate), [Validators.required]],
        ip: [quote.ipAddress, [Validators.required]],
        stairType: [quote.stairType, [Validators.required]],
        stairWoodType: [quote.stairWoodType, [Validators.required]],
        stairForm: [quote.stairForm, [Validators.required]],
        stairHeight: [quote.stairHeight, [Validators.required]],
        stairAmount: [quote.stairAmount, [Validators.required]],
        railingType: [quote.railingType, [Validators.required]],
        railingLength: [quote.railingLength],
        ceilingCasing: [quote.ceilingCasing],
        ceilingCasingLength: [quote.ceilingCasingLength],
        reisers: [quote.reisers],
        extraInfo: [quote.extraInfo],
        remarks: [quote.remarks],
        extraElements: this.formBuilder.array([]),
        discountPercentage: [quote.discountPercentage],
        addToPrice: [quote.addToPrice],
      });

      extraElements.forEach(element => this.addExtraElement(element));

      this.preiseForm.get('extraInfo')?.disable();
    } else {
      this.preiseForm = this.formBuilder.group({
        id: ['', [Validators.required]],
        offerNumber: ['', [Validators.required]],
        salutation: ['', [Validators.required]],
        name: ['', [Validators.required]],
        surname: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        postalCode: ['', [Validators.required]],
        phoneNumber: ['', [Validators.required]],
        installationDate: [new Date(), [Validators.required]],
        ip: ['', [Validators.required]],
        stairType: ['', [Validators.required]],
        stairWoodType: ['', [Validators.required]],
        stairForm: ['', [Validators.required]],
        stairHeight: [15, [Validators.required]],
        stairAmount: [1, [Validators.required]],
        railingType: ['', [Validators.required]],
        railingLength: [0],
        ceilingCasing: [false],
        ceilingCasingLength: [0],
        reisers: [false],
        extraInfo: [''],
        remarks: [''],
        extraElements: this.formBuilder.array([]),
        discountPercentage: [''],
        addToPrice: [''],
      });
    }

    this.ceilingCasingChanges();

    this.preiseForm.get('id')?.disable();
    this.preiseForm.get('offerNumber')?.disable();
    this.preiseForm.get('ip')?.disable();
  }

  get extraElements() {
    return <FormArray>this.preiseForm.get('extraElements');
  }

  public addExtraElement(element?: ExtraElements) {
    const elementForm = this.formBuilder.group({
      name: [element?.name || '', Validators.required],
      value: [element?.value || 0, [Validators.required, Validators.min(0)]],
      quantity: [element?.quantity || 0, [Validators.required, Validators.min(0)]],
      quantityUnit: [element?.quantityUnit || '', Validators.required],
    });

    this.extraElements.push(elementForm);
  }

  removeExtraElement(index: number) {
    this.extraElements.removeAt(index);
  }

  public onSaveClick(): void {
    if (this.preiseForm.valid) {
      const values = this.preiseForm.value;
      // make integers
      values.railingLength = +values.railingLength;
      values.ceilingCasingLength = +values.ceilingCasingLength;
      values.addToPrice = +values.addToPrice;
      values.discountPercentage = +values.discountPercentage;
      if (this.isAddMode) {
        // Creating new Quote
        this.quoteService.newQuote(values).subscribe({
          next: (data: QuoteItem) => {
            showSaveSuccess(this.messageService);
            this.router.navigate(['/dashboard/edit-quote', data.id], { relativeTo: this.route });
          },
          error: () => {
            showSaveError(this.messageService);
          },
        });
      } else {
        // Saving Quote
        const id = this.preiseForm.get('id')?.value;
        this.quoteService.updateQuote(values, +id!).subscribe({
          next: () => {
            showSaveSuccess(this.messageService);
            this.getPdf(this.quote.pdfFileName);
            window.location.reload();
          },
          error: () => {
            showSaveError(this.messageService);
          },
        });
      }
    } else {
      markFormControlsAsDirty(this.preiseForm);
    }
  }

  public prevPreise(): void {
    this.quoteService.getPrevId(this.quote.id).subscribe({
      next: prevId => {
        if (prevId) {
          this.router.navigate(['/dashboard/edit-quote', prevId], { relativeTo: this.route });
        } else {
          this.showNoPrevQuote();
        }
      },
    });
  }

  public nextPreise(): void {
    this.quoteService.getNextId(this.quote.id).subscribe({
      next: nextId => {
        if (nextId) {
          this.router.navigate(['/dashboard/edit-quote', nextId], { relativeTo: this.route });
        } else {
          this.showNoNextQuote();
        }
      },
    });
  }

  public sendQuoteViaEmail() {
    // TODO: doesn't work
    const isFormModified = checkIfFormIsModified(this.preiseForm);
    const quoteId = this.quote.id;

    if (!isFormModified) {
      this.sendEmail(quoteId);
    } else {
      // Show prompt to save the form
      this.showConfirmSave();
    }
  }

  public showNoPrevQuote() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'There is no more previous quotes',
    });
  }

  public showNoNextQuote() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'There is no more next quotes',
    });
  }

  public getLastSentEdited(quote: QuoteItem) {
    const quoteSentLength = quote.status.quoteSent?.length;
    if (quoteSentLength) {
      this.lastQuoteSent = quote.status.quoteSent![quoteSentLength - 1];
    }
    const quoteEditedLength = quote.status.quoteEdited?.length;
    if (quoteEditedLength) {
      this.lastQuoteEdited = quote.status?.quoteEdited![quoteEditedLength - 1];
    }
  }

  public openHistoryPdf(filename: string) {
    const url = this.buildPdfFileNameWithPath(filename);
    this.urlHelperService.get(url).subscribe(pdfBlob => {
      const urlFromBlob = URL.createObjectURL(pdfBlob);
      window.open(urlFromBlob);
    });
  }

  private sendEmail(id: number) {
    this.emailService.sendQuote(id).subscribe({
      next: () => {
        showSendSuccess(this.messageService);
      },
      error: () => {
        showSendError(this.messageService);
      },
    });
  }

  private showConfirmSave() {
    this.confirmationService.confirm({
      message: `Before sending email you have to save the quote. <br>Do you want to save this quote? <br><br>`,
      header: 'Save confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button p-button-text',
      rejectButtonStyleClass: 'p-button-danger p-button-text',
      acceptIcon: 'pi pi-save',
      rejectIcon: 'none',

      accept: () => {
        this.onSaveClick();
      },
      reject: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Rejected',
          detail: 'Quote NOT saved and Quote NOT sent',
        });
      },
    });
  }

  private ceilingCasingChanges() {
    // Initial 
    this.showCeilingCasingLength = this.preiseForm.get('railingLength')?.value;
    // Subscription when ceilingCasing changes value
    this.preiseForm.get('ceilingCasing')?.valueChanges.subscribe(value => {
      if (value) {
        const railingLength = this.preiseForm.get('railingLength')?.value;
        this.showCeilingCasingLength = value;
        this.preiseForm.controls['ceilingCasingLength'].setValidators([Validators.required]);
        this.preiseForm.controls['ceilingCasingLength'].setValue(railingLength, {
          emitEvent: false,
        });
        this.preiseForm.controls['ceilingCasingLength'].updateValueAndValidity({
          emitEvent: false,
        });
      } else {
        this.showCeilingCasingLength = value.ceilingCasing;
        this.preiseForm.controls['ceilingCasingLength'].setValidators(null);
        this.preiseForm.controls['ceilingCasingLength'].setValue(0, { emitEvent: false });
        this.preiseForm.controls['ceilingCasingLength'].updateValueAndValidity({
          emitEvent: false,
        });
      }
    });
  }

  private getPdf(fileName: string) {
    const iframeParams = '#navpanes=0';
    const randomNumber = Math.floor(Math.random() * (999999 - 100000)) + 100000;
    const pdfPathAndFileName = this.buildPdfFileNameWithPath(fileName);
    const url = `${pdfPathAndFileName}${iframeParams}&${randomNumber}`;
    this.urlHelperService.get(url).subscribe(pdfBlob => {
      const urlFromBlob = URL.createObjectURL(pdfBlob);
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlFromBlob);
    });
  }

  private buildPdfFileNameWithPath(fileName: string) {
    return `${environment.storageUrl}/storage/${fileName}`;
  }
}
