import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';

import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { EditorModule } from 'primeng/editor';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { EmailService } from 'src/app/services/email.service';
import { AdditionalInfo } from 'src/app/types/additionalInfo';
import { markFormControlsAsDirty } from 'src/app/utils/forms';
import { showSaveError, showSaveSuccess } from 'src/app/utils/toasts';

@Component({
  selector: 'app-pdf-email-extra-info-edit',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ButtonModule,
    EditorModule,
    InputGroupModule,
    InputTextModule,
    InputTextareaModule,
    TabViewModule,
  ],
  templateUrl: './pdf-email-extra-info-edit.component.html',
})
export class PdfEmailExtraInfoEditComponent implements OnInit {
  public editPdfEmailInfoForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private emailService: EmailService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.emailService.getAdditionalInfo().subscribe({
      next: additionalInfo => {
        const data = additionalInfo;
        this.buildForm(data);
      },
    });
  }

  public buildForm(data: AdditionalInfo) {
    this.editPdfEmailInfoForm = this.formBuilder.group({
      createdBy: [data.createdBy, [Validators.required]],
      customerNumber: [data.customerNumber],
      contactInfo: [data.contactInfo, [Validators.required]],
      textUnderTables: [data.textUnderTables, [Validators.required]],
      textUnderTables2: [data.textUnderTables2, [Validators.required]],
      mailContentBeforeTable: [data.mailContentBeforeTable, [Validators.required]],
      mailContentAfterTable: [data.mailContentAfterTable, [Validators.required]],
    });
  }

  public onSaveClick(): void {
    if (this.editPdfEmailInfoForm.valid) {
      const values = this.editPdfEmailInfoForm.value;
      this.emailService.saveAdditionalInfo(values).subscribe({
        next: () => {
          showSaveSuccess(this.messageService);
        },
        error: () => {
          showSaveError(this.messageService);
        },
      });
    } else {
      markFormControlsAsDirty(this.editPdfEmailInfoForm);
    }
  }
}
