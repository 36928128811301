import { MessageService } from 'primeng/api/messageservice';

export function showSaveSuccess(messageService: MessageService) {
  messageService.add({
    severity: 'success',
    summary: 'Success',
    detail: 'Save sucessfull',
  });
}

export function showSaveError(messageService: MessageService) {
  messageService.add({
    severity: 'error',
    summary: 'Error',
    detail: 'Save NOT sucessfull',
  });
}

export function showSendSuccess(messageService: MessageService) {
  messageService.add({
    severity: 'success',
    summary: 'Success',
    detail: 'Email sent sucessfully',
  });
}


export function showSendError(messageService: MessageService) {
  messageService.add({
    severity: 'error',
    summary: 'Error',
    detail: 'Email was NOT sent',
  });
}
