import { FormControl, FormGroup } from '@angular/forms';

export function markFormControlsAsDirty(form: FormGroup) {
  Object.keys(form.controls).forEach(key => {
    markControlDirty(<FormControl>form.get(key));
  });
}

export function markControlDirty(formControl: FormControl) {
  formControl.markAsDirty();
}

export function checkIfFormIsModified(form: FormGroup): boolean {
  if (form.status === 'VALID') {
    console.log("it's the same");
    return false;
  } else {
    console.log("it's different");
    return true;
  }
}
