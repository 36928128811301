import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PdfResponse } from '../types/pdf';
import { HttpBase } from '../utils/http-base';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private http: HttpBase) {}

  getPdf(fileName: string): Observable<PdfResponse> {
    return this.http.get(`storage/${fileName}`);
  }
}
