import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { Observable } from 'rxjs/internal/Observable';
import { QuoteService } from 'src/app/services/quote.service';
import { Meta, SortAndFilter } from 'src/app/types/general';

import { QuoteItem, QuoteResponse } from '../../../types/quote';

@Component({
  selector: 'app-quote-list',
  standalone: true,
  imports: [
    CommonModule,
    ConfirmDialogModule,
    ButtonModule,
    TableModule,
    IconFieldModule,
    InputTextModule,
    InputIconModule,
    RouterModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './quote-list.component.html',
  styleUrl: './quote-list.component.less',
})
export class QuoteListComponent {
  public quotes: QuoteItem[] = [];
  public dataAndMeta$: Observable<QuoteResponse>;
  public quotesMeta: Meta = <Meta>{
    page: 1,
    take: 50,
    itemCount: 0,
    pageCount: 1,
    hasPreviousPage: false,
    hasNextPage: true,
  };
  loading: boolean = true;

  public sortingAndFiltering: SortAndFilter;

  constructor(
    private quoteService: QuoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
  ) {}

  // Lazy loaded event from p-table
  public loadQuotes(event: TableLazyLoadEvent) {
    this.loading = true;

    const selectedPage = Math.floor(<number>event.first / <number>event.rows) + 1;
    this.sortingAndFiltering = {
      sortBy: event.sortField ? event.sortField : 'id',
      sortOrder: event.sortOrder === 1 ? 'ASC' : 'DESC',
      filter: event.globalFilter ? event.globalFilter : '',
    };

    this.quotesMeta = {
      take: <number>event!.rows,
      page: selectedPage,
      itemCount: this.quotesMeta.itemCount,
      pageCount: 1,
      hasPreviousPage: this.quotesMeta.hasPreviousPage,
      hasNextPage: this.quotesMeta.hasNextPage,
    };

    this.getQuotes(this.sortingAndFiltering);
  }

  public clearSearch(table: Table) {
    table.clear();
  }

  public getQuotes(sortingAndFiltering?: SortAndFilter) {
    this.loading = true;
    this.quoteService.getQoutes(this.quotesMeta, sortingAndFiltering).subscribe(res => {
      this.quotes = res.data;
      this.quotesMeta = res.meta;
      this.loading = false;
    });
  }

  public editQuote(id: number) {
    this.router.navigate(['../edit-quote', id], { relativeTo: this.activatedRoute });
  }

  public showDeleteConfirmDialog(quote: QuoteItem) {
    this.confirmationService.confirm({
      message: `Do you want to delete this quote? <br><br>
      id: ${quote.id} <br>
      name: ${quote.name}`,
      header: 'Delete confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text p-button-text',
      acceptIcon: 'pi pi-trash',
      rejectIcon: 'none',

      accept: () => {
        this.removeQuote(quote.id);
      },
      reject: () => {
        this.showNotDeletedToast();
      },
    });
  }

  public removeQuote(id: number) {
    this.quoteService.deleteQuote(id).subscribe({
      next: () => {
        this.showDeletedToast();
        this.getQuotes(this.sortingAndFiltering);
      },
      error: () => {
        this.showNotDeletedToast();
      },
    });
  }

  private showDeletedToast() {
    this.messageService.add({
      severity: 'success',
      summary: 'Deleted',
      detail: 'Quote deleted',
    });
  }

  private showNotDeletedToast() {
    this.messageService.add({
      severity: 'error',
      summary: 'Rejected',
      detail: 'Quote NOT deleted',
    });
  }
}
