<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="preise-edit-container flex gap-2">
  <div class="form">
    <div class="flex flex-column gap-2 mb-5">
      @if (quote.status) {
        <div>
          Was sent?
          @if (lastQuoteSent) {
            <i class="pi pi-check"></i>
            ({{ lastQuoteSent.author }} - {{ lastQuoteSent.date | date: 'd/M/yy, H:mm' }})
          } @else {
            <i class="pi pi-times"></i>
          }
        </div>
        <div>
          Was edited?
          @if (lastQuoteEdited) {
            <i class="pi pi-check"></i>
            ({{ lastQuoteEdited.author }} - {{ lastQuoteEdited.date | date: 'd/M/yy, H:mm' }})
          } @else {
            <i class="pi pi-times"></i>
          }
        </div>
      }
    </div>

    @if (preiseForm) {
      <form
        [formGroup]="preiseForm"
        (ngSubmit)="onSaveClick()"
        class="card flex flex-column gap-2 mb-5"
      >
        <p-tabView>
          <p-tabPanel header="Preise Info">
            <div class="form-container flex flex-wrap gap-2">
              <label for="stairType">Art der Treppe</label>
              <p-inputGroup>
                <p-dropdown
                  formControlName="stairType"
                  [options]="stairTypes"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Select stair type"
                  [style]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="stairWoodType">Holzart</label>
              <p-inputGroup>
                <p-dropdown
                  formControlName="stairWoodType"
                  [options]="stairWoodTypes"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Select stair wood type"
                  [style]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="stairForm">Form</label>
              <p-inputGroup>
                <p-dropdown
                  formControlName="stairForm"
                  [options]="stairForms"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Select stair for"
                  [style]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="railingType">Brüstungsgeländertyp</label>
              <p-inputGroup>
                <p-dropdown
                  formControlName="railingType"
                  [options]="railingTypes"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Select railing type"
                  [style]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="railingLength">Brüstungsgeländerlänge [meters]</label>
              <p-inputGroup>
                <input formControlName="railingLength" type="text" pInputText />
              </p-inputGroup>

              <label for="ceilingCasing">Deckenrandverkleidung</label>
              <p-inputGroup>
                <p-inputSwitch formControlName="ceilingCasing" />
              </p-inputGroup>

              @if (showCeilingCasingLength) {
                <label for="ceilingCasingLength"
                  >Deckenrandverkleidung Length</label
                >
                <p-inputGroup>
                  <input formControlName="ceilingCasingLength" type="text" pInputText />
                </p-inputGroup>
              }

              <label for="reisers">Setzstufen</label>
              <p-inputGroup>
                <p-inputSwitch formControlName="reisers" />
              </p-inputGroup>

              <label for="stairHeight">Treppenhöhe</label>
              <p-inputGroup>
                <p-dropdown
                  formControlName="stairHeight"
                  [options]="stairHeights"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Select stair height"
                  [style]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="stairAmount">Anzahl der Treppen </label>
              <p-inputGroup>
                <p-dropdown
                  formControlName="stairAmount"
                  [options]="stairAmount"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Select stair amount"
                  [style]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="extraInfo">Extra info from client</label>
              <p-inputGroup>
                <textarea rows="5" cols="30" pInputTextarea formControlName="extraInfo"> </textarea>
              </p-inputGroup>

              <label for="remarks">Remarks (ankmerungen)</label>
              <p-inputGroup>
                <textarea rows="5" cols="30" pInputTextarea formControlName="remarks"> </textarea>
              </p-inputGroup>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Extra elements">
            <div formArrayName="extraElements">
              <div
                *ngFor="let element of extraElements.controls; let i = index"
                [formGroupName]="i"
                class="flex flex-wrap flex-initial gap-3 mb-5"
              >
                <div class="w-5">
                  <label for="name-{{ i }}">Name</label>
                  <p-inputGroup>
                    <input formControlName="name" pInputText />
                  </p-inputGroup>
                </div>

                <div class="w-5">
                  <label for="value-{{ i }}">Value</label>
                  <p-inputGroup>
                    <input formControlName="value" pInputText />
                  </p-inputGroup>
                </div>

                <div class="w-5">
                  <label for="quantity-{{ i }}">Quantity</label>
                  <p-inputGroup>
                    <input formControlName="quantity" pInputText />
                  </p-inputGroup>
                </div>

                <div class="w-5">
                  <label for="quantityUnit-{{ i }}">Quantity Unit</label>
                  <p-inputGroup>
                    <input formControlName="quantityUnit" pInputText />
                  </p-inputGroup>
                </div>
                <span class="pi pi-trash clickable" (click)="removeExtraElement(i)"></span>
              </div>

              <span class="pi pi-plus clickable" (click)="addExtraElement()"></span>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Contact Information">
            <div class="form-container flex flex-wrap gap-2">
              <label for="id">ID</label>
              <p-inputGroup>
                <input formControlName="id" type="text" pInputText />
              </p-inputGroup>

              <label for="offerNumber">Offer number (Kostenvoranschlag)</label>
              <p-inputGroup>
                <input formControlName="offerNumber" type="text" pInputText />
              </p-inputGroup>

              <label for="salutation">Salutation</label>
              <p-inputGroup>
                <p-inputGroupAddon>
                  <i class="pi pi-user"></i>
                </p-inputGroupAddon>
                <p-dropdown
                  formControlName="salutation"
                  [options]="salutationOptions"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Select salutation"
                  [style]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="name">Name</label>
              <p-inputGroup>
                <p-inputGroupAddon>
                  <i class="pi pi-user"></i>
                </p-inputGroupAddon>
                <input formControlName="name" pInputText placeholder="Name" />
              </p-inputGroup>

              <label for="surname">Surname</label>
              <p-inputGroup>
                <p-inputGroupAddon>
                  <i class="pi pi-user"></i>
                </p-inputGroupAddon>
                <input formControlName="surname" pInputText placeholder="Surname" autocomplete="given-name" />
              </p-inputGroup>

              <label for="email">Email</label>
              <p-inputGroup>
                <p-inputGroupAddon>
                  <i class="pi pi-at"></i>
                </p-inputGroupAddon>
                <input formControlName="email" pInputText placeholder="E-mail" autocomplete="family-name" />
              </p-inputGroup>

              <label for="phoneNumber">Phone</label>
              <p-inputGroup>
                <p-inputGroupAddon>
                  <i class="pi pi-phone"></i>
                </p-inputGroupAddon>
                <input formControlName="phoneNumber" pInputText placeholder="Telephone" />
              </p-inputGroup>

              <label for="postalCode">Postal code (Lieferort)</label>
              <p-inputGroup>
                <p-inputGroupAddon>
                  <i class="pi pi-directions"></i>
                </p-inputGroupAddon>
                <input formControlName="postalCode" pInputText placeholder="Postal code" />
              </p-inputGroup>

              <label for="installationDate">Installation date (Montagetermin)</label>
              <p-inputGroup>
                <p-inputGroupAddon>
                  <i class="pi pi-calendar"></i>
                </p-inputGroupAddon>
                <p-calendar
                  formControlName="installationDate"
                  dateFormat="dd.mm.yy"
                  [style]="{ width: '100%' }"
                  [inputStyle]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="ip">IP Address</label>
              <p-inputGroup>
                <p-inputGroupAddon>
                  {{ quote.countryCode }}
                </p-inputGroupAddon>
                <input formControlName="ip" pInputText placeholder="IP Address" />
              </p-inputGroup>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Discount">
            <div class="form-container flex flex-wrap gap-2">
              <label for="discountPercentage">Discount (%)</label>
              <p-inputGroup>
                <input formControlName="discountPercentage" type="text" pInputText />
              </p-inputGroup>

              <label for="addToPrice">Add to price</label>
              <p-inputGroup>
                <input formControlName="addToPrice" type="text" pInputText />
              </p-inputGroup>
            </div>
          </p-tabPanel>
        </p-tabView>

        <div class="submit">
          <p-button label="Save" (click)="onSaveClick()"></p-button>
          <input [style.display]="'none'" type="submit" />
        </div>

        @if (!isAddMode) {
          <div class="send-quote">
            <p-button label="Send quote @" (click)="sendQuoteViaEmail()"></p-button>
          </div>
        }
      </form>
    }
  </div>
  <div class="pdf-viewer">
    @if (!isAddMode) {
      <div><h1>PDF Viewer</h1></div>
      @if (pdfUrl) {
        <iframe [src]="pdfUrl" width="100%" height="1000px" frameBorder="0"></iframe>
      }

      @if (quote.status && quote.status.quoteSent) {
        <div class="sentHistory">
          <h3>Sent history</h3>
          @for (quoteSent of quote.status.quoteSent; track quoteSent; let i = $index) {
            <div class="sentHistoryElement">
              {{ i + 1 }}) {{ quoteSent.date | date: 'd/M/yy, H:mm' }} ({{ quoteSent.author }}):
              <span
                class="clickable"
                (click)="openHistoryPdf(quoteSent.pdf)"
                (keydown)="openHistoryPdf(quoteSent.pdf)"
                >Open PDF <i class="pi pi-file-pdf"></i
              ></span>
              ({{ quoteSent.pdf }}.pdf)
            </div>
          }
        </div>
      }
    }
  </div>
</div>
@if (!isAddMode) {
  <div class="navigation flex gap-3">
    <div class="prev-quote">
      <p-button label="Prev preise" severity="secondary" (click)="prevPreise()"></p-button>
    </div>
    <div class="next-quote">
      <p-button label="Next preise" severity="secondary" (click)="nextPreise()"></p-button>
    </div>
  </div>
}

<p-confirmDialog />
