<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="quote-list-container">
  <h2>All Preise forms</h2>
<ng-container>
  <p-table
    #quoteTable
    [value]="quotes"
    [lazy]="true"
    (onLazyLoad)="loadQuotes($event)"
    dataKey="id"
    [paginator]="true"
    [rows]="quotesMeta.take"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    currentPageReportTemplate="Showing {first} to {last} from {totalRecords} Preise quotes"
    [rowsPerPageOptions]="[20, 50, 100]"
    [loading]="loading"
    [globalFilterFields]="['offer_number', 'postalCode', 'name', 'email']"
    [totalRecords]="quotesMeta.itemCount"
    styleClass="p-datatable-lg p-datatable-striped"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between align-items-center">
        <p-button
          label="Clear"
          class="p-button-outlined"
          icon="pi pi-filter-slash"
          (click)="clearSearch(quoteTable)"
          [outlined]="true"
        ></p-button>
        <p-iconField iconPosition="left">
          <p-inputIcon>
            <i class="pi pi-search"></i>
          </p-inputIcon>
          <input
            pInputText
            type="text"
            (input)="quoteTable.filterGlobal($any($event.target).value, 'contains')"
            placeholder="Search"
          />
        </p-iconField>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th pSortableColumn="id">ID <p-sortIcon field="id"></p-sortIcon></th>
        <th pSortableColumn="offer_number">
          Offer number <p-sortIcon field="offer_number"></p-sortIcon>
        </th>
        <th pSortableColumn="created_at">
          Creation date <p-sortIcon field="created_at"></p-sortIcon>
        </th>
        <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
        <th pSortableColumn="email">E-mail <p-sortIcon field="email"></p-sortIcon></th>
        <th>ZIP code</th>
        <th>Sent | Edited</th>
        <th>IP | Source</th>
        <th>Actions</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>{{ rowData.id }}</td>
        <td>{{ rowData.offerNumber }}</td>
        <td>{{ rowData.createdAt | date: 'd/M/yy, H:mm' }}</td>
        <td>{{ rowData.name }} {{ rowData.surname }}</td>
        <td>{{ rowData.email }}</td>
        <td>{{ rowData.postalCode }}</td>
        <td>
          @if (rowData.status.quoteSent && rowData.status.quoteSent[0]?.date) {
            <i class="pi pi-check"></i>
          } @else {
            <i class="pi pi-times"></i>
          }
          <span>&nbsp;|&nbsp;</span>
          @if (rowData.status.quoteEdited && rowData.status.quoteEdited[0]?.date) {
            <i class="pi pi-check"></i>
          } @else {
            <i class="pi pi-times"></i>
          }
        </td>
        <td>
          <span>[{{ rowData.countryCode }}] | </span>
          {{ rowData.source }}
        </td>
        <td>
          <div class="flex justify-content-between align-items-center">
            <i
              class="pi pi-file-edit cursor-pointer"
              style="font-size: 1.5rem"
              (click)="editQuote(rowData.id)"
            ></i>
            <i
              class="pi pi-trash cursor-pointer"
              style="font-size: 1.5rem"
              (click)="showDeleteConfirmDialog(rowData)"
            ></i>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="8">No search results.</td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
</div>

<p-confirmDialog />
